import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import { apiTrain } from '@/api'

const defaultState = () => ({
  tabIndex: 0,
  payloadSearch: null,
  resultSearchTrain: null,

  trainSelectedArray: [],
  coachSelectedArray: [],

  ticketSelectedArray: [], // NOTE nested array
})

export default {
  namespaced: true,
  state: {
    ...defaultState(), // FIXME using keep-alive with component result and reservation-book

    loading: false,
    loadingCategory: false,
    loadingSetting: false,

    settings: null,
    category: null,
  },

  getters: {
    getLoading: state => state.loading,
    isSearchRT: state => state.payloadSearch?.tripType === 'RT',
    getBookingCode: state => state.resultSearchTrain?.bookingCode || '',
    getListTrainStation: state => state.category?.listTrainStation || [],
    getListPassengerTarget: state => state.category?.listPassengerTarget || [],
    getSearchPayloadArray: state => {
      const {
        departure, arrival, departDate, returnDate, tripType,
      } = state.payloadSearch || {}
      if (tripType === 'RT') {
        return [
          {
            departure,
            arrival,
            departDate,
          },
          {
            departure: arrival,
            arrival: departure,
            departDate: returnDate,
          },
        ]
      }
      return [{ departure, arrival, departDate }]
    },
    getResultTrainArray: (state, getters) => {
      const go = state.resultSearchTrain?.listTrain || []
      const back = state.resultSearchTrain?.listTrainReturn || []
      const result = [go]
      if (getters.isSearchRT) result.push(back)
      return result
    },
  },

  mutations: {
    RESET_STORE(state) {
      Object.assign(state, { ...defaultState() })
    },

    REMOVE_BUTTON_DATA(state, { id, reset = false }) {
      state.data = reset ? [] : state.data.filter(button => button.id !== id)
    },
    SET_LOADING(state, { name, value }) {
      state[name] = value
    },
    SET_CATEGORY(state, val) {
      state.category = val
    },
    SET_SETTING(state, val) {
      state.settings = val
    },
    SET_DATA_SEARCH(state, val) {
      state.resultSearchTrain = val
    },
    SET_PAYLOAD_SEARCH(state, val) {
      state.payloadSearch = val
    },
    SET_TAB_INDEX(state, val) {
      state.tabIndex = val
    },
    SET_SEARCH_BOOKING_CODE(state, val) {
      state.searchBookingCode = val
    },

    SET_INITIAL_SELECT_TRAIN(state) {
      const initTrainArr = [state.resultSearchTrain.listTrain[0]]
      if (!isEmpty(state.resultSearchTrain.listTrainReturn)) {
        initTrainArr.push(state.resultSearchTrain.listTrainReturn[0])
      }
      state.trainSelectedArray = initTrainArr
    },
    SET_INITIAL_SELECT_COACH(state) { // FIXME case ko có hành trình => chuyển thành action
      const initCoachArr = [state.resultSearchTrain.listTrain[0].coachOfTrains[0]]
      if (!isEmpty(state.resultSearchTrain.listTrainReturn)) {
        initCoachArr.push(state.resultSearchTrain.listTrainReturn[0].coachOfTrains[0])
      }
      state.coachSelectedArray = initCoachArr
    },
    SET_INITIAL_SELECT_TICKET(state) {
      const initTicketArr = [[]]
      if (state.payloadSearch.tripType === 'RT') {
        initTicketArr.push([])
      }
      state.ticketSelectedArray = initTicketArr
    },

    SET_SELECTED_TRAIN(state, val) {
      const clone = cloneDeep(state.trainSelectedArray)
      clone[state.tabIndex] = val
      state.trainSelectedArray = clone
    },
    SET_SELECTED_COACH(state, val) {
      const clone = cloneDeep(state.coachSelectedArray)
      clone[state.tabIndex] = val
      state.coachSelectedArray = clone
    },

    DELETE_TICKET(state, { storeTicketIndex, ticketDelete }) {
      const clone = cloneDeep(state.ticketSelectedArray)
      clone[storeTicketIndex] = clone[storeTicketIndex].filter(t => !(t.trainId === ticketDelete.trainId
                                                                   && t.coachId === ticketDelete.coachId
                                                                   && t.seatId === ticketDelete.seatId
      ))
      state.ticketSelectedArray = clone
    },
    ADD_TICKET(state, { storeTicketIndex, ticket }) {
      const clone = cloneDeep(state.ticketSelectedArray)
      clone[storeTicketIndex].push(ticket)
      state.ticketSelectedArray = clone
    },

  },

  actions: {
    async handleGetCategory({ commit }) {
      commit('SET_LOADING', { name: 'loadingCategory', value: true })
      return new Promise((resolve, reject) => {
        apiTrain.getCategory()
          .then(res => {
            commit('SET_CATEGORY', res.data)
            return resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingCategory', value: false })
          })
      })
    },
    async handleGetSetting({ commit }) {
      commit('SET_LOADING', { name: 'loadingSetting', value: true })
      return new Promise((resolve, reject) => {
        apiTrain.getSetting()
          .then(res => {
            commit('SET_SETTING', res.data)
            return resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_LOADING', { name: 'loadingSetting', value: false })
          })
      })
    },
    async searchTrain({ getters, commit }, data) {
      commit('SET_LOADING', { name: 'loading', value: true })
      const payload = {
        ...data,
        bookingCode: getters.getBookingCode,
      }

      commit('SET_PAYLOAD_SEARCH', payload)

      return new Promise((resolve, reject) => {
        apiTrain
          .searchTrain(payload)
          .then(res => {
            commit('SET_DATA_SEARCH', res)
            commit('SET_INITIAL_SELECT_TRAIN')
            commit('SET_INITIAL_SELECT_COACH')
            commit('SET_INITIAL_SELECT_TICKET')

            return resolve(res)
          })
          .catch(error => reject(error))
          .finally(() => {
            commit('SET_LOADING', { name: 'loading', value: false })
          })
      })
    },

    handleToggleSelectTicket({ state, commit }, { storeIndex = null, ticket }) {
      const storeTicketIndex = storeIndex ?? state.tabIndex

      const listTickets = state.ticketSelectedArray[storeTicketIndex]
      const index = listTickets.findIndex(it => it.trainId === ticket.trainId
                                             && it.coachId === ticket.coachId
                                             && it.seatId === ticket.seatId)

      if (index !== -1) {
        const ticketDelete = {
          trainId: ticket.trainId,
          coachId: ticket.coachId,
          seatId: ticket.seatId,
        }
        console.log('DELETE_TICKET', { storeTicketIndex, ticketDelete })
        commit('DELETE_TICKET', { storeTicketIndex, ticketDelete })
      } else {
        console.log('ADD_TICKET', { storeTicketIndex, ticket })
        commit('ADD_TICKET', { storeTicketIndex, ticket })
      }
    },

    fetchSeatByCoach(ctx, payload) { // FIXME thêm data vào result train
      return apiTrain.fetchSeatByCoach(payload)
    },
    pickTicket(ctx, payload) {
      return apiTrain.pickTicket(payload)
    },
    dropTicket(ctx, payload) {
      return apiTrain.dropTicket(payload)
    },
    saveBookings(ctx, payload) {
      return apiTrain.saveBookings(payload)
    },
  },

}
