import { computed, ref, watchEffect } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'

import storeModule from '@train/trainStoreModule'

export default function useTrainHandle() {
  if (!store.hasModule('app-train')) {
    store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }

  // states
  const loading = computed(() => store.state['app-train'].loading)
  const tabIndex = computed(() => store.state['app-train'].tabIndex)
  const statePayloadSearch = computed(() => store.state['app-train'].payloadSearch)
  const stateResultSearch = computed(() => store.state['app-train'].resultSearchTrain) // (object) result search train

  const stateLoadingSetting = computed(() => store.state['app-train'].loadingSetting)
  const getSetting = computed(() => store.state['app-train'].settings)

  const stateLoadingCategory = computed(() => store.state['app-train'].loadingCategory)
  const getCategory = computed(() => store.state['app-train'].category)

  const stateSelectedTickets = computed(() => store.state['app-train'].selectedTicket) // (array)
  const stateSelectedTicketsReturn = computed(() => store.state['app-train'].selectedTicketReturn) // (array)

  const stateTrainSelectedArray = computed(() => store.state['app-train'].trainSelectedArray) // (array) tàu đã chọn
  const stateCoachSelectedArray = computed(() => store.state['app-train'].coachSelectedArray) // (array) khoang theo tàu
  const stateTicketSelectedArray = computed(() => store.state['app-train'].ticketSelectedArray) // (array-nested) vé đã chọn

  // getters
  const getListTrainStation = computed(() => store.getters['app-train/getListTrainStation']) // (array) list trạm dừng
  const getSearchPayloadArray = computed(() => store.getters['app-train/getSearchPayloadArray']) // (array)

  const getBookingCode = computed(() => store.getters['app-train/getBookingCode']) // (string) bookingCode
  const getResultTrainArray = computed(() => store.getters['app-train/getResultTrainArray']) // (array nested) train result search

  // fetch category
  if ((!getCategory.value || isEmpty(getListTrainStation.value)) && !stateLoadingCategory.value) {
    store.dispatch('app-train/handleGetCategory')
  }
  // fetch setting
  if (!getSetting.value && !stateLoadingSetting.value) {
    store.dispatch('app-train/handleGetSetting')
  }

  async function searchTrain(payload) {
    const res = await store.dispatch('app-train/searchTrain', payload)
    return res
  }

  function handleSetTabIndex(index) {
    if (index === tabIndex.value) return
    store.commit('app-train/SET_TAB_INDEX', index)
  }

  // pickTicket req
  //   {
  //     "dateOfMonth": {{dateOfMonth}}, // data train
  //     "ticketId": {{ticketId}}, // seat data
  //     "bookingCode": "{{bookingCode}}", get booking
  //     "direction": {{direction}}, chiều đi 0, chiều về 1
  //     "trainId": {{trainId}}, // data train
  //     "coachId": {{coachId}}, // data coach
  //     "seatId": {{seatId}},
  //     "physicalTrainId": {{physicalTrainId}}
  // }

  function handleClickSeat(seat) {
    const train = stateTrainSelectedArray.value[tabIndex.value]
    const coach = stateCoachSelectedArray.value[tabIndex.value]

    const ticket = {
      fullName: '',
      identifyNumber: '',
      passengerTargetCode: 1,
      ticketId: seat.seatStatus.ticketId,
      refTicketId: null,
      isReturn: false,
      departureKM: train.departureKM, //  train
      arrivalKM: train.arrivalKM, //  train
      dateOfMonth: train.dateOfMonth, // train
      totalPrice: seat.totalPrice, // seat
      physicalTrainId: train.physicalTrainId, // train
      trainId: train.id,
      seatTypeId: seat.seatTypeId,
      ticketPrice: seat.ticketPrice,
      insurancePrice: seat.insurancePrice,
      discountPassenger: 0,
      discountPromotion: 0,
      // "discountSignature": "",
      // "promotions": [
      //   {
      //     "detailId": 0,
      //     "masterId": 0,
      //     "typeDC": 0,
      //     "valueDC": 0,
      //     "price": 0,
      //     "voucherCode": "string",
      //     "identifyNumber": "string",
      //     "ticketListApply": [
      //       0
      //     ]
      //   }
      // ],
      seatId: seat.id,
      coachId: coach.id,
      trainCode: train.trainCode,
      coachNumber: coach.coachNumber,
      seatName: seat.seatNumber,
      seatGroupCode: coach.seatGroupCode,
      departureTime: train.departureTime,
      arrivalTime: train.arrivalTime,
    }

    store.dispatch('app-train/handleToggleSelectTicket', { ticket })
  }

  function resolveDataSeat(availSeats, seatName, itineraryIndex) {
    const seatData = availSeats.find(seat => seat.seatNumber && Number(seat.seatNumber) === seatName)
    return {
      nameId: seatName,
      itineraryIndex,
      notExist: !seatData,
      ...seatData,
    }
  }

  function isAvailableSeat(seat) {
    if (seat?.notExist) return false
    const storeExist = stateTicketSelectedArray.value[seat.itineraryIndex].find(it => it.coachId === seat.coachId && it.seatId === seat.id)
    if (seat?.seatStatus?.status?.id === 3 && !storeExist) return true
    return false
  }

  function resolveVariantSeat(seat) {
    if (seat?.notExist) return 'danger'
    const storeExist = stateTicketSelectedArray.value[seat.itineraryIndex].find(it => it.coachId === seat.coachId && it.seatId === seat.id)
    if (storeExist) return 'warning'
    if (seat?.seatStatus?.status?.id === 3) return 'info'
    return 'secondary'
  }

  const defaultDeparture = {
    id: 55,
    code: 'HNO',
    name: 'Hà Nội',
    searchKeys: 'ha noi, hn, hanoi, HNO, Hà Nội, Ha Noi',
  }

  const defaultArrival = {
    id: 129,
    code: 'SGO',
    name: 'Sài Gòn',
    searchKeys: 'sai gòn, saigon, sai gon, hcm, sg, hồ chí minh, ho chi minh, hochiminh, SGO, Sài Gòn, Sai Gon',
  }

  return {
    defaultDeparture,
    defaultArrival,

    loading,
    tabIndex,
    stateResultSearch,
    stateLoadingCategory,
    stateSelectedTickets,
    stateSelectedTicketsReturn,
    statePayloadSearch,
    stateTrainSelectedArray,
    stateCoachSelectedArray,
    stateTicketSelectedArray,

    getSetting,
    getCategory,
    getSearchPayloadArray,
    getBookingCode,
    getListTrainStation,
    getResultTrainArray,

    searchTrain,
    handleSetTabIndex,
    handleClickSeat,
    resolveDataSeat,
    resolveVariantSeat,
    isAvailableSeat,
  }
}
